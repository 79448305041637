import { CustomLoaderFunctionArgs } from "../../shared/router/RouteLoader";
import { FinBoxResponse } from "../../shared/services/APIResponse";

import { LoanApplicationService } from "../../services/LoanApplication";

export default async function LoanPaymentsLoader({
  params,
  getToken,
  pageName,
}: CustomLoaderFunctionArgs): Promise<FinBoxResponse<any>[] | FinBoxResponse> {
  const { loanID } = params;
  const token = getToken();

  return Promise.all([
    LoanApplicationService.getPayments({
      loanApplicationID: loanID!,
      token,
      pageName: pageName!,
    }),
    LoanApplicationService.getVirtualAccounts({
      loanApplicationID: loanID!,
      token,
    }),
  ]);
}
