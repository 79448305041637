import { AuthService } from "./authService";
import { ExportsService } from "./exportsService";
import { UsersService } from "./usersService";
import { MembersService } from "./membersService";
import { SourceEntityService } from "./sourceEntityService";
import { CreditLineService } from "./creditLineService";
import { ActiveCreditLineService } from "./activeCreditLineService";
import { OverdraftService } from "./overdraftService";
import * as IntegrationService from "./integrationService";

const authService = new AuthService();
const exportsService = new ExportsService(authService);
const userService = new UsersService(authService);
const membersService = new MembersService(authService);
const sourceEntityService = new SourceEntityService(authService);
const creditLineService = new CreditLineService(authService);
const activeCreditLineService = new ActiveCreditLineService(authService);
const overdraftService = new OverdraftService(authService);

export {
  authService,
  userService,
  exportsService,
  membersService,
  sourceEntityService,
  creditLineService,
  activeCreditLineService,
  overdraftService,
  IntegrationService as integrationService,
};
