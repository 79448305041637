import classNames from "classnames";
import { useOutletContext } from "react-router";
import { CheckIcon } from "@heroicons/react/20/solid";
import { Combobox, Transition } from "@headlessui/react";
import { Fragment, useEffect, useRef, useState } from "react";
import { ChevronUpDownIcon } from "@heroicons/react/24/outline";

import { debounce } from "../../../shared/utils/DateUtil";
import {
  DropdownWithSearchOption,
  DropdownWithSearchParams,
} from "./interface";
import { InfoCircle } from "@untitled-ui/icons-react";

function MultipleCheckbox(
  { selected = false, active = false },
  whiteLabelStyle: any
) {
  return (
    <div
      className={classNames(
        "flex items-center justify-center border h-4 w-[21px] rounded-sm",
        selected
          ? `${whiteLabelStyle?.theme?.color?.border || "border-indigo-600"}`
          : "border-gray-200",
        active ? "border-white" : ""
      )}
    >
      {selected ? (
        <span
          className={`w-4 h-4 rounded-sm flex items-center justify-center ${
            active
              ? ""
              : `text-white ${
                  whiteLabelStyle?.theme?.color?.darkbg || "bg-indigo-600"
                }`
          }`}
        >
          <CheckIcon
            className="h-3 w-3"
            aria-hidden="true"
          />
        </span>
      ) : null}
    </div>
  );
}

function SingleCheckmark({ selected = false, active = false }) {
  return (
    <div
      className={classNames(
        "flex items-center justify-center w-5 h-5",
        selected ? "" : "",
        active ? "" : ""
      )}
    >
      {selected ? (
        <span
          className={`w-5 h-5 flex items-center justify-center ${
            active ? "text-white" : "text-indigo"
          }`}
        >
          <CheckIcon
            className="h-4 w-4"
            aria-hidden="true"
          />
        </span>
      ) : null}
    </div>
  );
}

export default function DropdownWithSearch({
  searchFunc,
  options,
  placeholder,
  multiple,
  onChange,
  whiteLabelStyle,
  styles = {
    container: "",
    label: "",
    dropdown: "",
    button: "",
    chevronIcon: "",
    buttonContainer: "",
    dropdownItem: "",
  },
  style,
  label = "",
  mode = "",
  selectedItems,
  emptyText = "No results found",
  hasSearch = true,
  disabled = false,
  requiredIcon = false,
  hasSelectAll = false,
  whiteLabelStyle: any,
  inputPlaceholder = "",
  showDropdownIndicator = true,
  onClick,
  infoIcon = false,
  infoTooltip = "",
}: DropdownWithSearchParams) {
  const [selectAllOption, setSelectAllOptions] = useState(false);
  const [selected, setSelected] = useState<
    NonNullable<DropdownWithSearchParams["selectedItems"]>
  >(selectedItems || (multiple ? [] : { name: "", value: "" }));
  const queryRef = useRef<{ value: string }>(null);
  const [isSearched, setIsSearched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (selectedItems && (!selected || !selected?.length)) {
      setSelected(selectedItems);
    }
  }, [selectedItems]);

  function onOptionSelect(
    data: DropdownWithSearchOption | Array<DropdownWithSearchOption>
  ) {
    setSelected(data);
    onChange(data);
  }

  function comparator(
    a: DropdownWithSearchOption,
    b: DropdownWithSearchOption
  ) {
    return a.value === b.value;
  }

  let multipleButtonText = "";
  if (multiple) {
    if (selected?.length > 0) {
      multipleButtonText = selected[0].name;
    }
    let additionalCount = selected?.length > 1 ? selected?.length - 1 : 0;
    if (additionalCount) {
      multipleButtonText += " + " + additionalCount + " item(s)";
    }
  }

  const selectAllHandler = (check: boolean) => {
    if (!multiple) return;

    let notSelectedOptions = [] as any;
    options?.forEach((option: any) => {
      const isExist = selected?.some(
        (item: any) => item?.value === option?.value
      );
      if (!isExist) {
        notSelectedOptions?.push(option);
      }
    });

    const selectedOptions = check ? [...selected, ...notSelectedOptions] : [];
    setSelected(selectedOptions);
    onChange?.(selectedOptions);
    setSelectAllOptions(check);
  };

  useEffect(() => {
    if (multiple && selectedItems?.length === options?.length) {
      setSelectAllOptions(true);
    } else {
      setSelectAllOptions(false);
    }
  }, [selectedItems, options]);

  const handleSearchChange = debounce(async (text: string) => {
    setIsLoading(true);
    await searchFunc?.(text);
    setIsLoading(false);
    if (text?.length === 0) {
      setIsSearched(false);
    } else {
      setIsSearched(true);
    }
    queryRef.current = { value: text };
  }, 300);

  return (
    <div className={classNames("w-72", styles.container)}>
      <Combobox
        value={selected}
        onChange={onOptionSelect}
        multiple={multiple}
        by={comparator}
        disabled={disabled}
      >
        <div className="relative">
          {label && (
            <Combobox.Label
              className={classNames(
                "block font-medium text-gray-700 text-xs mb-2",
                styles.label
              )}
            >
              {label}{" "}
              {requiredIcon && (
                <span className="text-inherit text-red-700 ml-1">*</span>
              )}
              {infoIcon && (
                <div
                  className="inline-block items-center text-inherit cursor-pointer"
                  title={infoTooltip}
                >
                  <InfoCircle className="w-4 h-4" />
                </div>
              )}
            </Combobox.Label>
          )}
          <div
            className={classNames(
              "relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left",
              styles.buttonContainer ?? ""
            )}
          >
            <Combobox.Button
              tabIndex={0}
              className={classNames(
                "rounded-lg w-full border border-gray-200 flex items-center justify-between py-2 px-4 text-xs text-gray-700",
                styles.button ?? "",
                disabled ? "bg-gray-100" : ""
              )}
              style={style?.button ?? {}}
              title={
                multiple
                  ? selected?.length > 0 &&
                    selected.map((s) => s.name).join(",")
                  : selected.name
              }
              onClick={async (event) => {
                if (hasSearch) {
                  setIsLoading(true);
                  await searchFunc?.("");
                  setIsSearched(false);
                  setIsLoading(false);
                }
                onClick?.(event);
              }}
            >
              {multiple ? (
                selected?.length > 0 ? (
                  <ul className="h-full flex pr-10 truncate">
                    {multipleButtonText}
                  </ul>
                ) : (
                  placeholder
                )
              ) : (
                selected.name || placeholder
              )}
              {showDropdownIndicator ? (
                <ChevronUpDownIcon
                  aria-hidden="true"
                  className={classNames(
                    "h-5 w-5 text-gray-400",
                    styles.chevronIcon ?? ""
                  )}
                />
              ) : null}
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => {
              if (queryRef.current) {
                queryRef.current.value = "";
              }
            }}
          >
            <div
              className={classNames(
                "absolute mt-1 h-auto w-full shadow-lg bg-white border border-gray-50 rounded-md py-2 z-10",
                styles.dropdown,
                mode === "reverse" ? "flex flex-col bottom-[40px]" : ""
              )}
            >
              {hasSearch && (
                <Combobox.Input
                  tabIndex={0}
                  className={classNames(
                    "block w-[calc(100%_-_16px)] border border-gray-200 mx-2 rounded-lg py-2 px-2 text-xs leading-4 text-gray-900 truncate outline-none mb-1",
                    mode === "reverse" ? "order-2" : "order-1"
                  )}
                  displayValue={(opts) => queryRef?.current?.value}
                  onChange={(e) => {
                    e.preventDefault();
                    if (
                      e.target.value?.length > 0 &&
                      /^\s*$/g.test(e.target.value)
                    ) {
                      e.target.value = e.target.value.slice(0, 0);
                      queryRef.current = { value: e.target.value };
                      return;
                    }

                    handleSearchChange(e.target.value);
                  }}
                  placeholder={inputPlaceholder}
                />
              )}

              <Combobox.Options
                className={classNames(
                  "w-full py-1 text-xs focus:outline-none overflow-auto max-h-60 bg-white",
                  mode === "reverse" ? "order-1" : "order-2"
                )}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {options?.length === 0 ? (
                  <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                    {isLoading
                      ? null
                      : isSearched
                        ? "No results found"
                        : emptyText}
                  </div>
                ) : (
                  <>
                    {hasSelectAll && multiple && (
                      <>
                        <label
                          className={classNames(
                            "inline-flex items-center hover:text-white w-full px-2 py-2",
                            whiteLabelStyle?.theme?.hover?.darkbg1 ||
                              "hover:bg-indigo-600"
                          )}
                        >
                          <input
                            type="checkbox"
                            checked={selectAllOption}
                            onChange={(e) =>
                              selectAllHandler(e?.target?.checked)
                            }
                            className={classNames(
                              "form-checkbox h-4 w-4  transition duration-150 ease-in-out border-gray-200 rounded-[2px] ml-2  focus:ring-0 focus:ring-transparent",
                              whiteLabelStyle?.theme?.color?.text ||
                                "text-indigo-600"
                            )}
                          />
                          <span className="ml-2 text-inherit text-xs">
                            Select All
                          </span>
                        </label>
                      </>
                    )}
                    {options?.map((option, optionIdx) => (
                      <Combobox.Option
                        key={optionIdx}
                        className={({ active }) =>
                          `relative cursor-default select-none py-2 px-4 ${
                            active
                              ? `${
                                  whiteLabelStyle?.theme?.color?.bg ||
                                  "bg-indigo-600"
                                } text-white`
                              : "text-gray-900"
                          } ${styles?.dropdownItem}`
                        }
                        value={option}
                      >
                        {({ selected, active }) => {
                          return (
                            <div className="flex items-center justify-between w-full">
                              <div className="flex items-center justify-start">
                                {multiple && (
                                  <MultipleCheckbox
                                    selected={selected}
                                    active={active}
                                  />
                                )}
                                <span
                                  className={`block w-full truncate pl-2 ${
                                    selected ? "font-normal" : "font-normal"
                                  }}`}
                                >
                                  {option.name}
                                </span>
                              </div>
                              {!multiple && (
                                <div>
                                  <SingleCheckmark
                                    active={active}
                                    selected={selected}
                                  />
                                </div>
                              )}
                            </div>
                          );
                        }}
                      </Combobox.Option>
                    ))}
                  </>
                )}
              </Combobox.Options>
            </div>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
}
