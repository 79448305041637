import { useLoaderData, useOutletContext, useParams } from "react-router-dom";

import LoanTimelineTab from "../../../unified-dashboard/src/pages/LoanTimeline";

import GetAuthContext from "../../shared/context/AuthContext";
import { CustomLoaderFunctionArgs } from "../../shared/router/RouteLoader";

import { LoanApplicationService } from "../../services/LoanApplication";

export async function loanTimelineLoader({ getToken, params, pageName }: CustomLoaderFunctionArgs) {
  const { loanID } = params;

  return await LoanApplicationService.getLoanActivity({
    loanApplicationID: loanID!,
    token: getToken(),
    pageName: pageName!,
    page: 1,
  });
}

function LoanTimeline({ pageName }: { pageName?: string }) {
  const { loanID } = useParams();
  const { data }: any = useLoaderData();
  const { fetchToken } = GetAuthContext();
  const { loanDetails } = useOutletContext() as any;
  const token = fetchToken();

  return (
    <LoanTimelineTab
      pageName={pageName}
      isLenderPlatform={false}
      data={data}
      loanDetails={loanDetails}
      token={token}
      loanID={loanID}
    />
  );
}

export default LoanTimeline;
