import { objectKeys } from "../../shared/utils/DateUtil";
import { RequestDocument } from "./interface";

export type LoanDetailsAttributes =
  | "loanType"
  | "loanApplicationNum"
  | "customerID"
  | "sourceEntityName"
  | "mobile"
  | "agentCode"
  | "dsaName"
  | "dsaEmail"
  | "dsaMobile"
  | "cibil"
  | "cibilStatus"
  | "fis"
  | "experian"
  | "experianStatus"
  | "createdAt"
  | "updatedAt"
  | "source"
  | "name"
  | "maritalStatus"
  | "educationLevel"
  | "fathersName"
  | "currentAddress"
  | "permanentAddress"
  | "loanPurpose"
  | "address"
  | "residenceType"
  | "gender"
  | "dob"
  | "utmCampaign"
  | "utmContent"
  | "utmMedium"
  | "utmSource";

export type LoanDetailsFields<key> = {
  [key in LoanDetailsAttributes]: {
    label: string;
    allowCopy: boolean;
    category: string;
  };
};

export const loanDetailFields: LoanDetailsFields<LoanDetailsAttributes> = {
  loanType: {
    label: "Loan Type",
    allowCopy: false,
    category: "loan-details",
  },
  loanApplicationNum: {
    label: "Loan ID",
    allowCopy: false,
    category: "loan-details",
  },
  customerID: {
    label: "Customer ID",
    allowCopy: false,
    category: "loan-details",
  },
  sourceEntityName: {
    label: "Partner Name",
    allowCopy: false,
    category: "loan-details",
  },
  mobile: {
    label: "Mobile",
    allowCopy: false,
    category: "communication",
  },
  agentCode: {
    label: "Agent Code",
    allowCopy: false,
    category: "additional-info",
  },
  dsaName: {
    label: "Agent Name",
    allowCopy: false,
    category: "additional-info",
  },
  dsaEmail: {
    label: "Agent Email",
    allowCopy: false,
    category: "communication",
  },
  dsaMobile: {
    label: "Agent Mobile",
    allowCopy: false,
    category: "communication",
  },
  cibil: {
    label: "CIBIL Score",
    allowCopy: false,
    category: "additional-info",
  },
  cibilStatus: {
    label: "CIBIL Status",
    allowCopy: false,
    category: "additional-info",
  },
  experian: {
    label: "Experian Score",
    allowCopy: false,
    category: "additional-info",
  },
  experianStatus: {
    label: "Experian Status",
    allowCopy: false,
    category: "additional-info",
  },
  fis: {
    label: "FIS",
    allowCopy: false,
    category: "additional-info",
  },
  fisStatus: {
    label: "FIS Status",
    allowCopy: false,
    category: "additional-info",
  },
  createdAt: {
    label: "Created At",
    allowCopy: false,
    category: "loan-details",
  },
  updatedAt: {
    label: "Updated At",
    allowCopy: false,
    category: "loan-details",
  },
  utmCampaign: {
    label: "UTM Campaign",
    allowCopy: false,
    category: "additional-info",
  },
  utmContent: {
    label: "UTM Content",
    allowCopy: false,
    category: "additional-info",
  },
  utmMedium: {
    label: "UTM Medium",
    allowCopy: false,
    category: "additional-info",
  },
  utmSource: {
    label: "UTM Source",
    allowCopy: false,
    category: "additional-info",
  },
  source: {
    label: "Source",
    allowCopy: false,
    category: "additional-info",
  },
  name: {
    label: "Name",
    allowCopy: false,
    category: "general",
  },
  maritalStatus: {
    label: "Marital Status",
    allowCopy: false,
    category: "personal",
  },
  educationLevel: {
    label: "Education",
    allowCopy: false,
    category: "personal",
  },
  fathersName: {
    label: "Father's Name",
    allowCopy: false,
    category: "personal",
  },
  loanPurpose: {
    label: "Loan Purpose",
    allowCopy: false,
    category: "general",
  },
  currentAddress: {
    label: "Current Address",
    allowCopy: false,
    category: "personal",
  },
  permanentAddress: {
    label: "Permanent Address",
    allowCopy: false,
    category: "personal",
  },
  additionalPermanentAddress: {
    label: "Permanent Address",
    allowCopy: false,
    category: "personal",
  },
  additionalPermanentAddressType: {
    label: "Permanent Address Type",
    allowCopy: false,
    category: "personal",
  },
  residenceType: {
    label: "Residence Type",
    allowCopy: false,
    category: "personal",
  },
  dob: {
    label: "DOB",
    allowCopy: false,
    category: "personal",
  },
  gender: {
    label: "Gender",
    allowCopy: false,
    category: "personal",
  },
  pan: {
    label: "PAN",
    allowCopy: false,
    category: "general",
  },
  email: {
    label: "Email",
    allowCopy: false,
    category: "communication",
  },
  industry: {
    label: "Industry",
    allowCopy: false,
    category: "general",
  },
  mothersName: {
    label: "Mother's Maiden Name",
    allowCopy: false,
    category: "personal",
  },
  houseOwnership: {
    label: "Do you own your own home?",
    allowCopy: false,
    category: "personal",
  },
  registeredBusinessAddress: {
    label: "Ownership type of registered business address",
    allowCopy: false,
    category: "personal",
  },
  subindustry: {
    label: "Sub-Industry",
    allowCopy: false,
    category: "general",
  },
  alternateMobile: {
    label: "Alternate Mobile",
    allowCopy: false,
    category: "general",
  },
  isAssistedJourney: {
    allowCopy: false,
    category: "general",
    label: "Customer was assisted by employee",
  },
  cifNumberIndividual: {
    label: "Customer Number Individual",
    allowCopy: false,
    category: "personal",
  },
  employmentType: {
    allowCopy: false,
    category: "professional-details",
    label: "Employment Type",
  },
  companyName: {
    allowCopy: false,
    category: "professional-details",
    label: "Company Name",
  },
  officeEmail: {
    allowCopy: false,
    category: "professional-details",
    label: "Office / Business Email",
  },
  monthlyIncome: {
    allowCopy: false,
    category: "professional-details",
    label: "Monthly Income",
  },
  officeAddressCity: {
    allowCopy: false,
    category: "professional-details",
    label: "Office / Business Address City",
  },
  officeAddressLine1: {
    allowCopy: false,
    category: "professional-details",
    label: "Office / Business Address Line 1",
  },
  officeAddressLine2: {
    allowCopy: false,
    category: "professional-details",
    label: "Office / Business Address Line 2",
  },
  officeAddressState: {
    allowCopy: false,
    category: "professional-details",
    label: "Office / Business Address State",
  },
  officePincode: {
    allowCopy: false,
    category: "professional-details",
    label: "Office / Business Pincode",
  },
  udyamMobileNumber: {
    allowCopy: false,
    category: "professional-details",
    label: "Udyam Mobile Number",
  },
  udyamNumber: {
    allowCopy: false,
    category: "professional-details",
    label: "Udyam Number",
  },
  emailPD: {
    allowCopy: false,
    category: "professional-details",
    label: "Email",
  },
  isAutofillGSTDone: {
    allowCopy: false,
    category: "professional-details",
    label: "Is Autofill GST Done",
  },
  companyCategory: {
    allowCopy: false,
    category: "professional-details",
    label: "Company Category",
  },
  gstnid: {
    allowCopy: false,
    category: "professional-details",
    label: "GSTN ID",
  },
};

export const RELATION_MANAGER_DETAILS = [
  {
    name: "Serviceable Location",
    key: "serviceableLocation",
    value: "",
  },
  {
    key: "branchName",
    name: "Branch Name",
    value: "",
  },
  {
    name: "State",
    key: "state",
    value: "",
  },
  {
    key: "district",
    name: "District",
    value: "",
  },
  {
    value: "",
    key: "roBGID",
    name: "ABFL Sales Office / RO / CRE BG ID",
  },
  {
    value: "",
    key: "bgid",
    name: "ABFL Sales Manager 1 Employee BG ID",
    lavel: "salesManager1Details",
  },
  {
    value: "",
    key: "name",
    lavel: "salesManager1Details",
    name: "ABFL Sales Manager 1 Employee Name",
  },
  {
    value: "",
    key: "l1Managerbgid",
    name: "L1 Manager ID",
    lavel: "salesManager1Details",
  },
  {
    value: "",
    key: "l1ManagerName",
    name: "L1 Manager Name",
    lavel: "salesManager1Details",
  },
  {
    value: "",
    key: "rsmbgid",
    name: "RSM ID",
    lavel: "salesManager1Details",
  },
  {
    value: "",
    key: "rsmName",
    name: "RSM Name",
    lavel: "salesManager1Details",
  },
  {
    value: "",
    key: "zsmbgid",
    name: "ZSM ID",
    lavel: "salesManager1Details",
  },
  {
    value: "",
    key: "zsmName",
    name: "ZSM Name",
    lavel: "salesManager1Details",
  },
  {
    value: "",
    key: "sourcingTeam",
    name: "Sourcing Team",
  },
  {
    value: "",
    key: "sourcingChannel",
    name: "Sourcing Channel",
  },
  {
    value: "",
    key: "bgid",
    name: "ABFL Sales Manager 2 Employee BG ID",
    lavel: "salesManager2Details",
  },
  {
    value: "",
    key: "name",
    lavel: "salesManager2Details",
    name: "ABFL Sales Manager 2 Employee Name",
  },
  {
    value: "",
    key: "l1Managerbgid",
    name: "L1 Manager ID",
    lavel: "salesManager2Details",
  },
  {
    value: "",
    key: "l1ManagerName",
    name: "L1 Manager Name",
    lavel: "salesManager2Details",
  },
  {
    value: "",
    key: "rsmbgid",
    name: "RSM ID",
    lavel: "salesManager2Details",
  },
  {
    value: "",
    key: "rsmName",
    name: "RSM Name",
    lavel: "salesManager2Details",
  },
  {
    value: "",
    key: "zsmbgid",
    name: "ZSM ID",
    lavel: "salesManager2Details",
  },
  {
    value: "",
    key: "zsmName",
    name: "ZSM Name",
    lavel: "salesManager2Details",
  },
];

export const employementTypesDetails = {
  Salaried: [
    {
      key: "employmentStatus",
      label: "Employment Status",
      category: "employmentDetails",
    },
    {
      key: "companyName",
      label: "Company Name",
      category: "employmentDetails",
    },
    {
      key: "workEmail",
      label: "Work Email",
      category: "employmentDetails",
    },
    {
      key: "workAddress",
      label: "Work Address",
      category: "employmentDetails",
    },
    {
      key: "companyDomain",
      label: "Company Domain",
      category: "employmentDetails",
    },
    {
      key: "salaryMode",
      category: "employmentDetails",
      label: "How do you get your salary?",
    },
    {
      key: "monthlyIncome",
      label: "Monthly Income (INR)",
      category: "employmentDetails",
    },
    {
      key: "workExp",
      category: "employmentDetails",
      label: "Total years of work experience",
    },
  ],
  "Self Employed": [
    {
      key: "employmentStatus",
      label: "Employment Status",
      category: "employmentDetails",
    },
    {
      key: "profession",
      label: "Profession",
      category: "employmentDetails",
    },
    {
      key: "monthlyIncome",
      label: "Monthly Income (INR)",
      category: "employmentDetails",
    },
    {
      key: "workAddress",
      label: "Work Address",
      category: "employmentDetails",
    },
  ],
  "Business Owner": [
    {
      key: "employmentStatus",
      label: "Employment Status",
      category: "employmentDetails",
    },
    {
      key: "industry",
      label: "Industry",
      category: "employmentDetails",
    },
    {
      key: "subindustry",
      label: "Sub Industry",
      category: "employmentDetails",
    },
    {
      key: "businessName",
      label: "Business Name",
      category: "employmentDetails",
    },
    {
      key: "constitution",
      category: "employmentDetails",
      label: "Business Constitution",
    },
    {
      key: "annualSales",
      category: "employmentDetails",
      label: "Annual Business Sales",
    },
    {
      key: "currentAccount",
      category: "employmentDetails",
      label: "Do you have a current account?",
    },
  ],
};

const loanDetailsSidebarFieldNames = [
  "loanApplicationNum",
  // "loanType",
  "sourceEntityName",
  // "mobile",
  // "customerID",
  "agentCode",
  "dsaName",
  "dsaMobile",
  "dsaEmail",
];

export const loanDetailSidebarFields = objectKeys(loanDetailFields).filter(
  (key) => {
    return loanDetailsSidebarFieldNames.includes(key);
  }
);

export const REQUEST_DOCUMENT_ITEMS: Array<RequestDocument> = [
  {
    name: "Sales Team",
    key: "abfl_move_to_rad",
    workflowName: "abfl_kyc_wf",
    onClick: () => {},
    isActive: false,
    disabledTooltip: "A request for document has already been generated",
  },
  {
    name: "Operations Team (Video KYC)",
    key: "abfl_move_to_vkyc",
    workflowName: "abfl_kyc_wf",
    onClick: () => {},
    isActive: false,
    disabledTooltip: "A request for V-KYC has already been generated",
  },
];

export const visibilityChecks = {
  default: {
    hideCall: false,
  },
  abfl: {
    hideCall: true,
  },
};
