import { Tab } from "../../components/atomic/Tabs/interface";
import { LOAN_APPLICATION_DETAILS_TABS } from "../../services/LoanApplication/constants";
import { LEAD_DETAILS_TABS } from "../../services/Leads/constants";
import GetAuthContext from "../context/AuthContext";
import { FinBoxResponse } from "../services/APIResponse";
import { NO_HEADERS_RESPONSE, PAGES_LIST } from "./constants";

export function getSearchParamsFromUrl<T extends { name: string; value: string }>(
  url: string,
  typesList: Array<T>
) {
  const pageUrl = new URL(url);
  let searchParams = Object.fromEntries(pageUrl.searchParams);
  let searchType = "";
  let searchQuery = "";
  typesList?.some((type) => {
    if (searchParams?.[type.value]) {
      searchType = type.value;
      searchQuery = searchParams[searchType];
      return true;
    } else {
      return false;
    }
  });

  return {
    searchType,
    searchQuery,
  };
}

export function getFormattedCurrency(number: number) {
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
  }).format(number);
}

export function getFormattedNumber(number: number) {
  return new Intl.NumberFormat("en-IN", {
    minimumFractionDigits: 0,
  }).format(number);
}

export const blobCreationFromURI = (dataurl: any) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

export const debounce = <T extends (...args: any) => any>(func: T, delay: number) => {
  let debounceTimer: NodeJS.Timeout;
  return (...args) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func(...args), delay);
  };
};

export const CREDITLINE_TABS = [
  "Timeline",
  "Details",
  "Case Status",
  "Application Form",
  "CreditLine Details",
  "KYC",
  "CreditLine Transactions",
  "Bank Info",
  "Partner Details",
  "Payments",
  "Bank Statements",
  "Additional Docs",
  "Notes",
] as const;

export const PERSONAL_LOAN_TABS = [
  "Timeline",
  "Workflow Timeline",
  "Details",
  "Loan Term Deviation",
  "Case Status",
  "Application Form",
  "KYC",
  "Loan Offer",
  "Bank Info",
  "Partner Details",
  "Payments",
  "Bank Statements",
  "Additional Docs",
  "Notes",
] as const;

export const TABS_MAP = {
  loan_application: {
    Details: PAGES_LIST.LOAN_APPLICATION_DETAILS_TAB,
    "Case Status": PAGES_LIST.LOAN_APPLICATION_CASE_STATUS_TAB,
    Timeline: PAGES_LIST.LOAN_APPLICATION_TIMELINE_TAB,
    "Workflow Timeline": PAGES_LIST.LOAN_APPLICATION_WORKFLOW_TIMELINE_TAB,
    "CreditLine Details": PAGES_LIST.LOAN_APPLICATION_CREDITLINE_DETAILS_TAB,
    "CreditLine Transactions": PAGES_LIST.LOAN_APPLICATION_CREDITLINE_TRANSACTIONS_TAB,
    "Application Form": PAGES_LIST.LOAN_APPLICATION_FORM_TAB,
    KYC: PAGES_LIST.LOAN_APPLICATION_KYC_TAB,
    "Co-Applicants": PAGES_LIST.CO_APPLICANT_DETAILS_PAGE,
    Directors: PAGES_LIST.LOAN_APPLICATION_DIRECTOR_TAB,
    "Loan Offer": PAGES_LIST.LOAN_APPLICATION_LOAN_OFFER_TAB,
    "Bank Info": PAGES_LIST.LOAN_APPLICATION_BANK_INFO_TAB,
    "Partner Details": PAGES_LIST.LOAN_APPLICATION_PARTNER_INFO_TAB,
    Payments: PAGES_LIST.LOAN_APPLICATION_PAYMENTS_TAB,
    "Business Details": PAGES_LIST.LOAN_APPLICATION_BUSINESS_DETAILS_TAB,
    "Bank Statements": PAGES_LIST.LOAN_APPLICATION_BANK_STATEMENTS_TAB,
    "Additional Docs": PAGES_LIST.LOAN_APPLICATION_ADDITIONAL_DOCS_TAB,
    Notes: PAGES_LIST.LOAN_APPLICATION_NOTES_TAB,
    "Loan Term Deviation": PAGES_LIST.LOAN_APPLICATION_LOAN_TERM_DEVIATION_TAB,
  },
  leads: {
    Details: PAGES_LIST.LOAN_APPLICATION_DETAILS_TAB,
    "Case Status": PAGES_LIST.LOAN_APPLICATION_CASE_STATUS_TAB,
    Timeline: PAGES_LIST.LOAN_APPLICATION_TIMELINE_TAB,
    "Partner Details": PAGES_LIST.LOAN_APPLICATION_PARTNER_INFO_TAB,
    "Business Details": PAGES_LIST.LOAN_APPLICATION_BUSINESS_DETAILS_TAB,
    "Bank Statements": PAGES_LIST.LOAN_APPLICATION_BANK_STATEMENTS_TAB,
  },
} as const;

export const getTabsByProductType = (
  entityType: "loan_application" | "leads",
  productType: "credit_line" | "business_loan" | "personal_loan" | "overdraft",
  permissions = {},
  customTabs: any
) => {
  let filteredTabs: Array<Tab> = [];

  if (entityType === "leads") {
    filteredTabs = LEAD_DETAILS_TABS;
  } else {
    if (productType === "credit_line") {
      filteredTabs = customTabs["CREDITLINE_TABS"];
    } else if (productType === "overdraft") {
      filteredTabs = customTabs["CREDITLINE_TABS"];
    } else if (productType === "business_loan") {
      filteredTabs = customTabs["BUSINESS_LOAN_TABS"];
    } else if (productType === "personal_loan") {
      filteredTabs = customTabs["LOAN_TABS"];
    }
    filteredTabs = filteredTabs.map((tab) => LOAN_APPLICATION_DETAILS_TABS[tab.name]);
  }

  filteredTabs = filteredTabs.filter((tab) => {
    if (tab) {
      let readableName = TABS_MAP?.[entityType]?.[tab.name];
      return !!permissions[readableName];
    }
  });

  return filteredTabs;
};

export function getFilteredSearchTypes(disallowedFields, searchTypes) {
  return searchTypes.filter((searchType) => !disallowedFields.includes(searchType.value));
}

function objectToArray(obj: Record<string, any>) {
  let result: Array<{ name: string; value: any }> = [];
  Object.keys(obj ?? {}).map((key) => {
    if (obj[key] !== null && typeof obj[key] === "object" && !Array.isArray(obj[key])) {
      let valueArray = objectToArray(obj[key]);
      valueArray.map((valueEntry) => {
        result.push({
          name: (key !== "data" ? key + "." : "") + valueEntry.name,
          value: valueEntry.value,
        });
      });
    } else if (Array.isArray(obj[key])) {
      result.push({
        name: key,
        value: JSON.stringify(obj[key]),
      });
    } else {
      result.push({
        name: key,
        value: obj[key],
      });
    }
  });

  return result;
}

export function parseJsonToArray(json: Record<string, any>) {
  let { ...rest } = json ?? {};
  let rows: Array<{ name: string; value: any }> = [];

  if (rest && Object.keys(rest)?.length > 0) {
    rows = [...rows, ...objectToArray(rest)];
  }

  let labels: Record<string, any> = {};

  if (rows && rows?.length > 0) {
    rows.map((row) => {
      labels[row.name] = row.name;
    });
  }

  return { labels, rows };
}

export const updateRetriggerList = (id: string) => {
  let retriggerList = localStorage.getItem("retriggerList");
  if (retriggerList) {
    retriggerList = JSON.parse(retriggerList);
  }

  if (!retriggerList?.list?.find((item) => item == id)) {
    retriggerList = {
      list: [],
    };
    retriggerList?.["list"].push(id);

    localStorage.setItem("retriggerList", JSON.stringify(retriggerList));
  }
};

export const removeRetriggerItem = (id) => {
  let retriggerList = localStorage.getItem("retriggerList");
  retriggerList = JSON.parse(retriggerList);
  const index = retriggerList?.["list"].indexOf(id);

  if (index > -1) {
    retriggerList?.["list"].splice(index, 1);
    localStorage.setItem("retriggerList", JSON.stringify(retriggerList));
  }
};

export const insertAtIndex = (array, insertIndex, itemToInsert) => {
  let newArray = [...array];
  newArray.splice(insertIndex, 0, itemToInsert);
  return newArray;
};

export const isValidDate = (dateString: string) => {
  try {
    let date = new Date(dateString);
    return !isNaN(date.getTime());
  } catch (error) {
    return false;
  }
};

export async function noHeadersResponse(): Promise<FinBoxResponse> {
  console.log("No header response");
  return NO_HEADERS_RESPONSE;
}

export function checkPermission(
  pageName: string,
  sourceEntityID?: string,
  functionalAPI?: string
): boolean {
  const { userState } = GetAuthContext();
  const { rbacPermissions = {} } = userState || {};

  let isAllowed = Object.keys(rbacPermissions || {}).includes(pageName);
  if (sourceEntityID) {
    isAllowed = isAllowed && rbacPermissions[pageName].hasOwnProperty(sourceEntityID);
    if (functionalAPI) {
      isAllowed = isAllowed && rbacPermissions[pageName][sourceEntityID].includes(functionalAPI);
    }
  }
  return isAllowed;
}

export const forceDownloadLink = async (url: string, fileName: string) => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    const objURL = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = objURL;
    link.download = fileName;

    link.click();

    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Download failed", error);
  }
};

export const MULTI_DOMAIN_CONFIG = {
  "adityabirla-platform.finbox.in": {
    organizationID: "eabdf414-d3f3-4f1a-ad71-f01d59d9c05b",
  },
  "adityabirla-pl-platform.finbox.in": {
    organizationID: "65c5927f-5673-440e-a7be-e0b51e42c103",
  },
  "finbox-platform-dashboard-uat-12.s3-website.ap-south-1.amazonaws.com": {
    organizationID: "eabdf414-d3f3-4f1a-ad71-f01d59d9c05b",
  },
  "finbox-platform-dashboard-uat-10.s3-website.ap-south-1.amazonaws.com": {
    organizationID: "eabdf414-d3f3-4f1a-ad71-f01d59d9c05b",
  },
  "finbox-platform-dashboard-dev-6.s3-website.ap-south-1.amazonaws.com": {
    organizationID: "eabdf414-d3f3-4f1a-ad71-f01d59d9c05b",
  },
  "finbox-lending-platform-dev-2.s3-website.ap-south-1.amazonaws.com": {
    organizationID: "65c5927f-5673-440e-a7be-e0b51e42c103",
  },
  // localhost: {
  //   organizationID: "eabdf414-d3f3-4f1a-ad71-f01d59d9c05b",
  // },
};

export function getOrganizationConfigByDomain() {
  let hostname = window.location.hostname as keyof typeof MULTI_DOMAIN_CONFIG;
  return MULTI_DOMAIN_CONFIG?.[hostname] ?? null;
}

export function calculateDisbursalAmount(
  insurancePremium: number,
  gst: number,
  processingFee: number,
  amount: number
): number {
  let insurancePremiumPlusGST = ((insurancePremium + (gst * insurancePremium) / 100.0) * 100) / 100;

  // GST on processing fee
  let gstAmount = (processingFee * gst) / 100;
  let processingFeePlusGST = processingFee + gstAmount;
  processingFeePlusGST = (processingFeePlusGST * 100) / 100;

  return amount - processingFeePlusGST - insurancePremiumPlusGST;
}

export const whiteLabelStyling = (host: string) => {
  const styling = {
    "adityabirla-platform.finbox.in": {
      theme: {
        color: {
          bg: "!bg-[#CA1F34]",
          darkbg: "!bg-[#B01C2D]",
          text: "!text-[#CA1F34]",
          lightbg: "!bg-[#FADFE2]",
          border: "!border-[#CA1F34]",
          borderDark: "!border-[#B01C2D]",
        },
        hover: {
          bg: "hover:!bg-[#FADFE2]",
          text: "hover:!text-[#CA1F34]",
          darkbg1: "hover:!bg-[#CA1F34]",
          focus: "focus:!border-[#CA1F34]",
        },
        after: {
          color1: "after:bg-[#CA1F34]",
        },
      },
      navbar: {
        logo: {
          img: "/abc.png",
          className: "md:w-[152px] md:h-auto w-10 h-10",
        },
        poweredBy: true,
      },
      login: {
        hideFinbox: true,
        text: {
          title: "Sign in",
          subtitle: "Sign in to access your Platform Dashboard",
        },
      },
      forgetPassword: {
        hideFinbox: true,
        text: {
          title: "Forgot Password",
          subtitle:
            "Please enter the email address to which the password reset link has to be sent",
        },
      },
      resetPassword: {
        text: {
          title: "Reset Password",
          subtitle: "Reset Password to access your Platform Dashboard",
        },
      },
      input: {
        class: "focus:border-indigo-500 focus:outline-none focus:ring-indigo-500",
      },
    },
  };
  return styling[host];
};
