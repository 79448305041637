import { createContext, useContext, useEffect } from "react";
import mixpanel from "mixpanel-browser";
import { User } from "../../services/Common/interfaces";
const environment = import.meta.env.REACT_APP_ENV;
// const DEV_KEY = "65a75d5ee14712687fec9183c8035114";
// const PROD_KEY = "a34380c3222ecb7632ff66455f512db3";
const shouldSendToMixpanel = environment === "PROD";

export interface MixpanelContext {
  setIdentity: Function;
  setCurrentScreen: Function;
  trackUserAction: Function;
  resetIdentity: Function;
}

const MixpanelContext = createContext<MixpanelContext | null>(null);
export const MixpanelProvider = ({ children }: { children: JSX.Element }) => {
  const isMobile = window.innerWidth < 640;

  useEffect(() => {
    if (import.meta.env?.REACT_APP_ENV === "PROD") {
      initialize();
    }
  }, []);

  const initialize = () => {
    mixpanel.init(import.meta.env?.REACT_APP_MIXPANEL_PROJECT_TOKEN, {
      ignore_dnt: true,
      debug: true,
    });
  };

  const setIdentity = (userData: User) => {
    if (shouldSendToMixpanel) {
      const { email, name, mobile, userType, permissions = [] } = userData;
      // const sourceEntityIDs = permissions
      //   ?.map((item) => item.sourceEntityID)
      //   ?.join(", ");
      const isDsaUser = userType === "dsa" || userType === "sub-dsa";
      mixpanel?.identify(email);
      mixpanel?.people.set({ email: email });
      mixpanel?.register(
        {
          name,
          mobile,
          environment,
          userType,
          baseURL: window.location.origin,
          project: "Platform Dashboard",
          // sourceEntityID: isDsaUser ? sourceEntityIDs : "",
        },
        1
      );
    }
  };

  const setCurrentScreen = (screen: string, opts = {}) => {
    let defaultOpts = {
      env: environment,
      deviceType:
        window.matchMedia("(display-mode: standalone)").matches || navigator.standalone
          ? "pwa"
          : isMobile
            ? "mobile"
            : "browser",
    };
    if (!shouldSendToMixpanel) {
      console.log("MixPanel events dispatched: ", {
        screen,
        ...defaultOpts,
        ...opts,
      });
      return;
    }
    if (Object.keys(opts).length > 0) {
      mixpanel?.track(screen, {
        ...defaultOpts,
        ...opts,
      });
    } else {
      mixpanel?.track(screen, { ...defaultOpts });
    }
  };

  const trackUserAction = (eventName: string, opts = {}) => {
    if (!eventName) return;

    let defaultOpts = {
      env: environment,
      deviceType:
        window.matchMedia("(display-mode: standalone)").matches || navigator.standalone
          ? "pwa"
          : isMobile
            ? "mobile"
            : "browser",
    };

    if (!shouldSendToMixpanel) {
      console.log("MixPanel events dispatched: ", {
        eventName,
        ...defaultOpts,
        ...opts,
      });
      return;
    }

    if (Object.keys(opts).length > 0) {
      mixpanel.track(
        eventName,
        {
          ...defaultOpts,
          ...opts,
        },
        {
          send_immediately: true,
          transport: "sendBeacon",
        }
      );
    } else {
      mixpanel?.track(eventName, { ...defaultOpts });
    }
  };

  const resetIdentity = () => {
    if (shouldSendToMixpanel) {
      mixpanel?.reset();
    }
  };

  return (
    <MixpanelContext.Provider
      value={{ setIdentity, setCurrentScreen, trackUserAction, resetIdentity }}
    >
      {children}
    </MixpanelContext.Provider>
  );
};

const GetMixpanelContext = (): MixpanelContext => {
  const context = useContext(MixpanelContext);
  if (!context) throw new Error("MixpanelContext is not defined");
  return context;
};

export default GetMixpanelContext;
