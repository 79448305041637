export const snakeToTitle = (s: string): string => {
  return s.replace(/^_*(.)|_+(.)/g, (s, c, d) =>
    c ? c.toUpperCase() : " " + d.toUpperCase()
  );
};

export const checkForUndefinedAndNull = (s: any) => {
  return s !== undefined && s !== null;
};

export const getAddressString = (currentAddress: string): string => {
  try {
    const addressObj = JSON.parse(currentAddress);
    if (addressObj && Object.keys(addressObj)?.length) {
      const { line1, line2, city, state, pincode } = addressObj;
      const addressParts = [line1, line2, city, state, pincode].filter(Boolean);
      return addressParts.join(", ");
    }
    return "";
  } catch (ex) {
    console.log(new Error(ex).message);
    return "";
  }
};

export const getAddressType = (currentAddress: string): string => {
  try {
    const addressObj = JSON.parse(currentAddress);
    if (addressObj && Object.keys(addressObj)?.length) {
      const { addressType } = addressObj;
      return addressType;
    }
    return "";
  } catch (ex) {
    console.log(new Error(ex).message);
    return "";
  }
};
