import { Dispatch, SetStateAction, createContext, useContext, useEffect, useState } from "react";
import LZString from "lz-string";
import { User } from "../../services/Common/interfaces";
import { UserService } from "../../services/User";
import { authService } from "../../services/apis";
import {
  getPermissionsFromStorage,
  getTokenFromStorage,
  getUserInfoFromLocalStorage,
} from "../services/Fetch";
import { DashboardUserDTO, UserPermission } from "../../dtos/DashboardUserDTO";
import { USER_INITIAL_STATE } from "../../services/Common/constants";
import { PAGE_CONFIG } from "../utils/constants";
import usePartnersStore from "../../pages/Partners/store";

export const FINBOX_USER = "finbox-dashboard-user";
export const FINBOX_USER_PERMISSIONS = "finbox-dashboard-user-permissions";

interface IAuthContext {
  userState: Partial<User>;
  logout: () => void;
  loggingIn: () => void;
  fetchToken: () => string;
  loginFailure: (error: string) => void;
  updateUser: (userData: DashboardUserDTO & { isLoggingIn?: boolean }) => void;
  loginSuccess: (userData: DashboardUserDTO) => void;
  // canUser: (
  //   action: ActionType,
  //   module: ModuleType,
  //   sourceEntityIDList?: Array<string>
  // ) => boolean;
  fetchPermissions: () => UserPermission;
  clearLocalStorage: () => void;
  setUserState: Dispatch<SetStateAction<Partial<User>>>;
  getPageConfig: () => any;
  getDefaultConfig: () => any;
}

const AuthContext = createContext<IAuthContext | null>(null);

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const { clear: clearPartnerStore } = usePartnersStore();
  const userService = new UserService();
  const [userState, setUserState] = useState<Partial<User>>({
    ...USER_INITIAL_STATE,
    token: getTokenFromStorage(),
    isAuthenticated: !!getTokenFromStorage(),
    ...(getUserInfoFromLocalStorage() || {}),
    permissions: getPermissionsFromStorage(),
  });

  useEffect(() => {
    if (userState.isAuthenticated) {
      if (!(window as any).permissions) {
        (window as any).permissions = getPermissionsFromStorage();
      }
      if (!(window as any).token) {
        (window as any).token = getTokenFromStorage();
      }
    }
  }, [userState.isAuthenticated]);

  const loggingIn = () => {
    localStorage.removeItem(FINBOX_USER);
    authService.removeToken();
    (window as any).token = undefined;
    setUserState((prevState) => ({
      ...prevState,
      isLoggingIn: true,
      isLoggingInSuccess: false,
      isLoggingInFailure: false,
    }));
  };

  const loginSuccess = (user: DashboardUserDTO) => {
    localStorage.setItem(FINBOX_USER, LZString.compress(JSON.stringify(user)));
    authService.setToken(user.token);
    (window as any).token = user.token;
    (window as any).permissions = user.permissions;
    setUserState((prevState) => ({
      ...prevState,
      ...user,
      isAuthenticated: true,
      isLoggingIn: false,
      isLoggingInSuccess: true,
      isLoggingInFailure: false,
    }));
  };

  const loginFailure = (error: string) => {
    setUserState((prevState) => ({
      ...prevState,
      error,
      isAuthenticated: false,
      isLoggingIn: false,
      isLoggingInSuccess: false,
      isLoggingInFailure: true,
    }));
  };

  const fetchToken = () => {
    if (userState.token) return userState.token;
    return (window as any).token || getTokenFromStorage();
  };

  const fetchPermissions = () => {
    if (userState.permissions) return userState.permissions;
    return (window as any).permissions || getPermissionsFromStorage();
  };

  const logout = async () => {
    await userService.logout();
    clearPartnerStore();
    localStorage.removeItem(FINBOX_USER);
    localStorage.removeItem("filters");
    localStorage.removeItem(FINBOX_USER_PERMISSIONS);
    authService.removeToken();
    (window as any).token = undefined;
    (window as any).permissions = undefined;
    setUserState(USER_INITIAL_STATE);
  };

  const updateUser = (userDataObj: DashboardUserDTO) => {
    setUserState((prevState) => ({
      ...prevState,
      ...userDataObj,
    }));

    // TODO: Fix state management order when user profile is updated
    localStorage.setItem(FINBOX_USER, LZString.compress(JSON.stringify(userDataObj)));
    localStorage.setItem(
      FINBOX_USER_PERMISSIONS,
      LZString.compress(JSON.stringify({ permissions: userDataObj.permissions }))
    );
  };

  const getPageConfig = () => {
    let pageConfig: any = PAGE_CONFIG["default"];
    if (userState.organizationID && PAGE_CONFIG[userState.organizationID]) {
      pageConfig = PAGE_CONFIG[userState?.organizationID];
    }
    return pageConfig || {};
  };

  const getDefaultConfig = () => PAGE_CONFIG["default"];

  return (
    <AuthContext.Provider
      value={{
        userState: userState,
        loggingIn,
        loginSuccess,
        loginFailure,
        logout,
        updateUser,
        fetchToken,
        fetchPermissions,
        setUserState,
        clearLocalStorage() {
          localStorage.clear();
        },
        getPageConfig,
        getDefaultConfig,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

const GetAuthContext = (): IAuthContext => {
  const context = useContext(AuthContext);

  if (!context) throw new Error("AuthContext is not defined");
  return context;
};

export default GetAuthContext;
